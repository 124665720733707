<template>
 <div>
  <v-tabs class="tab-headerblk" v-model="currentTab" height="60px" show-arrows background-color="#282828" color="#facc15">
   <v-tab href="#tab-to-be-verified">To be Verified</v-tab>
   <v-tab href="#tab-verified-list">Verified List</v-tab>
   <v-tab href="#tab-dispute-list">Dispute List</v-tab>
   <!-- <v-tab href="#tab-rectified-list">Rectified List</v-tab> -->
  </v-tabs>
  <v-tabs-items v-model="currentTab">
   <v-tab-item id="tab-to-be-verified" class="tab-outer" transition="fade-transition" reverse-transition="fade-transition"  v-if="currentTab === 'tab-to-be-verified'">
    <VerifiedList :tab="currentTab" :verifiedStatus="0"></VerifiedList>
   </v-tab-item>
   <v-tab-item id="tab-verified-list" class="tab-outer" transition="fade-transition" reverse-transition="fade-transition" v-if="currentTab === 'tab-verified-list'">
    <VerifiedList :tab="currentTab" :verifiedStatus="10"></VerifiedList>
   </v-tab-item>
   <v-tab-item id="tab-dispute-list" class="tab-outer" transition="fade-transition" reverse-transition="fade-transition">
    <DisputeList :tab="currentTab" :verifiedStatus="1"></DisputeList>
   </v-tab-item>
   <!-- <v-tab-item id="tab-rectified-list" class="tab-outer" transition="fade-transition" reverse-transition="fade-transition" v-if="currentTab === 'tab-rectified-list'">
    <VerifiedList :tab="currentTab" :verifiedStatus="2"></VerifiedList>
   </v-tab-item> -->
  </v-tabs-items>
 </div>
</template>
<script>
import VerifiedList from "./VerifiedList.vue";
import DisputeList from "./DisputeList.vue";
export default {
 components: {
  VerifiedList,
  DisputeList,
 },
 data() {
  return {
   currentTab: "tab-verified-list",
  };
 },
};
</script>
<style scoped>
@import "../verification/verification.scss";
</style>
