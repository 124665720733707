<template>
 <div class="main-inner-blk">
  <v-card class="trip-filter-blk" elevation="3">
   <div class="filter-blk">
    <v-text-field v-model="filters.searchquery" placeholder="Search by Trip ID" label="Search by Trip ID" dense outlined hide-details clearable> </v-text-field>
   </div>
   <div class="filter-blk">
    <v-menu v-model="dateRangeModal" :nudge-right="40" :nudge-top="20" :close-on-content-click="false" transition="scale-transition" offset-y>
     <template v-slot:activator="{ on }">
      <v-text-field v-model="dateRangeText" outlined label="Date Range" readonly dense v-on="on" hide-details> </v-text-field>
     </template>

     <v-date-picker v-model="dateRangeValue" range color="black"></v-date-picker>
    </v-menu>
   </div>
   <div class="filter-blk">
    <v-autocomplete v-model="filters.serviceType" placeholder="Trip Type" label="Trip Type" item-text="name" item-value="id" outlined dense hide-details clearable :items="serviceTypeList">
    </v-autocomplete>
   </div>

   <v-btn color="#facc15" @click="resetFilter" text><i class="fas fa-undo"></i></v-btn>
  </v-card>

  <div class="trip-main-blk" :class="{ 'no-trip-blk': tripList.length === 0 }">
   <div v-if="tripList.length > 0">
    <div class="trip-list-content">
     <span class="trip-length-blk">Total Trips: {{ totalCount }}</span>
    </div>
    <TripsView :tripList="tripList" :listType="'dispute'" @fetchTrips="fetchTrips" @disputeResolved="fetchTrips" class="trip-details"></TripsView>
   </div>
   <div class="no-trip-blk" v-if="tripList.length === 0">
    <span>No Trips to Show</span>
   </div>
  </div>
  <div class="pagination">
   <v-pagination v-model="page" :length="Math.ceil(totalCount / pageSize)" :total-visible="6"></v-pagination>
  </div>

  <Loading v-if="loading"></Loading>
 </div>
</template>
<script>
import Loading from "../../common/Loading.vue";
// import Filters from "../../common/filter/Filters.vue";
import moment from "moment";
import axios from "../../../axios/axios-global";
import TripsView from "../verification/TripView.vue";
export default {
 components: { Loading, TripsView },
 props: {
  tab: String,
  // verifiedStatus: Number,
 },
 data() {
  return {
   tripList: [],
   loading: false,
   startDate: moment().format("YYYY-MM-DD"),
   endDate: moment().format("YYYY-MM-DD"),
   startedTripCount: 0,
   endedTripCount: 0,
   pageCount: 0,
   page: 1,
   pageSize: 50,
   totalCount: 0,

   filters: { searchquery: "", startDate: moment().format("YYYY-MM-DD"), endDate: moment().format("YYYY-MM-DD"), serviceType: -1 },
   dateRangeModal: false,
   dateRangeValue: [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
   serviceTypeList: [
    { id: 1, name: "PickUp" },
    { id: 2, name: "DropOff" },
    { id: 3, name: "Rentals" },
   ],
  };
 },
 methods: {
  fetchTrips() {
   this.getTripList(this.filters);
  },
  getFilters(data) {
   this.getTripList({ start: data.start, end: data.end, searchquery: data.search, state: data.state, branch: data.branch, serviceType: data.tripType, page: this.page, limit: this.pageSize });
  },
  async getTripList(data) {
   this.loading = true;
   this.tripList = [];
   data["limit"] = this.pageSize;
   data["verifiedStatus"] = 1;
   data.start = data.start ? moment(data.start).format("DD-MM-YYYY") : undefined;
   data.end = data.end ? moment(data.end).format("DD-MM-YYYY") : undefined;
   let branch = localStorage.getItem("selectedBranch");
   if (branch) {
    data.branch = JSON.parse(branch).id;
   }
   let response = await axios.post("/report/disputeList", data);
   if (response && response.status === 200) {
    this.loading = false;
    this.tripList = response.data.report;
    this.pageCount = response.data.nextpage;
    this.totalCount = response.data.rowCount;
   }
  },
  resetFilter() {
   this.filters = { searchquery: "", startDate: moment().format("YYYY-MM-DD"), endDate: moment().format("YYYY-MM-DD") };
   this.dateRangeModal = false;
   this.dateRangeValue = [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")];
   this.page = 1;
  },
 },
 mounted() {
  this.getFilters({
   start: this.filters.startDate,
   end: this.filters.endDate,
   search: this.filters.searchquery,
   page: this.page,
   limit: this.pageSize,
   tripType: this.filters.serviceType === null ? -1 : this.filters.serviceType,
  });
 },
 watch: {
  tab() {
   this.resetFilter();
   this.getFilters({
    start: this.filters.startDate,
    end: this.filters.endDate,
    search: this.filters.searchquery,
    page: this.page,
    limit: this.pageSize,
    tripType: this.filters.serviceType === null ? -1 : this.filters.serviceType,
   });
   //    if (this.tab === "tab-dispute-list") {
   // this.fetchTrips();
   //    }
  },
  filters: {
   handler() {
    this.tripList = [];
    this.page = 1;
    this.getFilters({
     start: this.filters.startDate,
     end: this.filters.endDate,
     search: this.filters.searchquery,
     page: this.page,
     limit: this.pageSize,
     tripType: this.filters.serviceType === null ? -1 : this.filters.serviceType,
    });
   },
   deep: true,
  },
  dateRangeValue() {
   if (this.dateRangeValue[0]) {
    if (this.dateRangeValue[1]) {
     if (this.dateRangeValue[0] > this.dateRangeValue[1]) {
      this.filters.startDate = moment(this.dateRangeValue[1]).format("YYYY-MM-DD");
      this.filters.endDate = moment(this.dateRangeValue[0]).format("YYYY-MM-DD");
     } else {
      this.filters.startDate = moment(this.dateRangeValue[0]).format("YYYY-MM-DD");
      this.filters.endDate = moment(this.dateRangeValue[1]).format("YYYY-MM-DD");
     }
     this.dateRangeModal = false;
     //  this.getFilters();
    } else {
     this.filters.startDate = moment(this.dateRangeValue[0]).format("YYYY-MM-DD");
     this.filters.endDate = moment().format("YYYY-MM-DD");
    }
   }
  },
 },
 computed: {
  dateRangeText() {
   let start = "";
   let end = "";
   if (this.dateRangeValue[0] > this.dateRangeValue[1]) {
    start = moment(this.dateRangeValue[1]).format("MMM DD");
    end = moment(this.dateRangeValue[0]).format("MMM DD");
   } else {
    start = moment(this.dateRangeValue[0]).format("MMM DD");
    end = moment(this.dateRangeValue[1]).format("MMM DD");
   }
   return `${start} ~ ${end}`;
  },
 },
};
</script>

<style lang="scss" scoped>
.filter-blk {
 width: 300px;
 margin-right: 10px;
}
</style>
