<template>
  <div class="vehicle-insert-modal">
   <v-card elevation="0" class="vehicle-basic-modal">
    <span class="vehicle-header-modal" v-if="editStatus">Edit - {{ vehicleDetails.vehicleNo }}</span>
    <span v-else class="vehicle-header-modal">Add vehicle</span>
    <div class="text-field-modal">
     <div class="text-field-blk">
      <v-text-field
       v-model="vehicleData.vehicleNo"
       label="Vehicle No *"
       placeholder="Vehicle No"
       outlined
       dense
       :error-messages="vehicleNoError"
       @blur="$v.vehicleData.vehicleNo.$touch()"
       @input="$v.vehicleData.vehicleNo.$touch()"
       @keydown="nameKeydown($event)"
       @paste="pasteKey($event)"
       @keydown.space.prevent
      ></v-text-field>
     </div>
     <div class="text-field-blk">
      <v-autocomplete
       v-model="vehicleData.manufacturer"
       label="Manufacturer *"
       placeholder="Manufacturer"
       outlined
       dense
       :items="filterData.manufacturer"
       return-object
       item-text="name"
       :error-messages="manufacturerError"
       @blur="$v.vehicleData.manufacturer.$touch()"
       @input="$v.vehicleData.manufacturer.$touch()"
      ></v-autocomplete>
     </div>
     <div class="text-field-blk">
      <v-autocomplete
       v-model="vehicleData.model"
       label="Model *"
       placeholder="Model"
       outlined
       dense
       :items="vehicleData.manufacturer.model"
       return-object
       item-text="name"
       :error-messages="modelError"
       @blur="$v.vehicleData.model.$touch()"
       @input="$v.vehicleData.model.$touch()"
      ></v-autocomplete>
     </div>
     <div class="text-field-blk">
      <v-autocomplete
       v-model="vehicleData.color"
       label="Color *"
       placeholder="Color"
       outlined
       dense
       :items="filterData.color"
       return-object
       item-text="name"
       :error-messages="colorError"
       @blur="$v.vehicleData.color.$touch()"
       @input="$v.vehicleData.color.$touch()"
      ></v-autocomplete>
     </div>
     <div class="text-field-blk">
      <v-autocomplete
       v-model="vehicleData.year"
       label="Year *"
       placeholder="Year"
       outlined
       dense
       return-object
       item-text="name"
       :items="yearData"
       :error-messages="yearError"
       @blur="$v.vehicleData.year.$touch()"
       @input="$v.vehicleData.year.$touch()"
      ></v-autocomplete>
     </div>
     <div class="text-field-blk">
      <v-autocomplete v-model="vehicleData.fuel" label="Fuel Type" placeholder="Fuel Type" outlined dense :items="filterData.fuel" return-object item-text="name"></v-autocomplete>
     </div>
     <div class="text-field-blk">
      <v-menu v-model="InsDateModel" :nudge-right="40" :nudge-top="20" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
       <template v-slot:activator="{ on }">
        <v-text-field v-model="vehicleData.insuranceExpiry" outlined label="Insurance Expiry" readonly dense v-on="on"> </v-text-field>
       </template>
       <v-date-picker @input="InsDateModel = false" :min="maxDate" v-model="vehicleData.insuranceExpiry" color="black"></v-date-picker>
      </v-menu>
     </div>
     <div class="text-field-blk">
      <v-menu v-model="TaxExpiryModel" :nudge-right="40" :nudge-top="20" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
       <template v-slot:activator="{ on }">
        <v-text-field v-model="vehicleData.taxExpiry" outlined label="Tax Validity Expiry" readonly dense v-on="on"> </v-text-field>
       </template>
       <v-date-picker @input="TaxExpiryModel = false" :min="maxDate" v-model="vehicleData.taxExpiry"  color="black"></v-date-picker>
      </v-menu>
     </div>
     <div class="text-field-blk">
      <v-menu v-model="RCExpiryModel" :nudge-right="40" :nudge-top="20" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
       <template v-slot:activator="{ on }">
        <v-text-field v-model="vehicleData.rcbookExpiry" outlined label="RC Expiry" readonly dense v-on="on"> </v-text-field>
       </template>
       <v-date-picker @input="RCExpiryModel = false" :min="maxDate" v-model="vehicleData.rcbookExpiry"  color="black"></v-date-picker>
      </v-menu>
     </div>
     <div class="text-field-blk">
      <v-menu v-model="PmtExpiryModel" :nudge-right="40" :nudge-top="20" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
       <template v-slot:activator="{ on }">
        <v-text-field v-model="vehicleData.carriagepermit" outlined label="Permit Expiry" readonly dense v-on="on"> </v-text-field>
       </template>
       <v-date-picker @input="PmtExpiryModel = false" :min="maxDate" v-model="vehicleData.carriagepermit" color="black"></v-date-picker>
      </v-menu>
     </div>
     <div class="text-field-blk">
      <v-menu v-model="FtsExpiryModel" :nudge-right="40" :nudge-top="20" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
       <template v-slot:activator="{ on }">
        <v-text-field v-model="vehicleData.fitnessExpiry" outlined label="Fitness Certificate Expiry" readonly dense v-on="on"> </v-text-field>
       </template>
       <v-date-picker @input="FtsExpiryModel = false" :min="maxDate" v-model="vehicleData.fitnessExpiry" color="black"></v-date-picker>
      </v-menu>
     </div>
     <div class="text-field-blk">
      <v-menu v-model="PlnExpiryModel" :nudge-right="40" :nudge-top="20" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
       <template v-slot:activator="{ on }">
        <v-text-field v-model="vehicleData.polutionExpiry" outlined label="PUC Expiry" readonly dense v-on="on"> </v-text-field>
       </template>
       <v-date-picker @input="PlnExpiryModel = false" :min="maxDate" v-model="vehicleData.polutionExpiry"  color="black"></v-date-picker>
      </v-menu>
     </div>
     <div class="button-blk">
      <v-btn color="#eea004" @click="submitDetails">Submit</v-btn>
      <v-btn color="#ed5e5e" @click="cancelSubmit">Close</v-btn>
     </div>
    </div>
   </v-card>
   <Loading v-if="loading"></Loading>
   <Confirm ref="confirm"></Confirm>
  </div>
 </template>
 
 <script>
 import { validationMixin } from "vuelidate";
 import { required } from "vuelidate/lib/validators";
 import axios from "@/axios/axios-global";
 import Loading from "@/components/common/Loading";
 import Confirm from "@/components/common/Confirm";
 import moment from "moment";
 import VehicleDocument from "@/components/vehicle-management/vehicle-document/VehicleDocument";
 export default {
  name: "VehicleInsert",
  components: { Confirm, Loading, VehicleDocument },
  props: {
   tabName: String,
   vehicleDetails: Object,
   editStatus: Boolean,
  },
  mixins: [validationMixin],
  validations() {
   return {
    vehicleData: {
     vehicleNo: { required },
     manufacturer: { required },
     model: { required },
     color: { required },
     year: { required },
    },
   };
  },
  data: () => {
   return {
    vehicleNo: "",
    manufacturer: "",
    model: "",
    color: "",
    year: "",
    fuel: "",
    filterData: "",
    loading: false,
    buttonName: "submit",
    vehicleId: "",
    documents: "",
    yearData: ["2010", "2011", "2012", "2013", "2014", "2015", "2016", "2017", "2018", "2019", "2020", "2021", "2022", "2023", "2024","2025"],
    InsDateModel: false,
    InsExpiryDate: null,
    TaxExpiryModel: false,
    TaxExpiryDate: null,
    RCExpiryModel: false,
    RCExpiryDate: null,
    PmtExpiryModel: false,
    PmtExpiryDate: null,
    FtsExpiryModel: false,
    FtsExpiryDate: null,
    PlnExpiryDate: null,
    PlnExpiryModel: false,
    maxDate: moment().format("YYYY-MM-DD"),
 
    vehicleData: {
     vehicleNo: "",
     manufacturer: {},
     model: {},
     color: {},
     fuel: {},
     year: "",
     rcbookExpiry: "",
     taxExpiry: "",
     fitnessExpiry: "",
     polutionExpiry: "",
     insuranceExpiry: "",
     carriagepermit: "",
    },
   };
  },
  async mounted() {
   await this.getFilters();
   if (this.editStatus === true) {
    await this.getVehicleDetails();
   } else {
    this.resetVehicleDetails();
    this.buttonName = "submit";
    this.driverId = "";
   }
  },
  watch: {
   manufacturer() {
    if (this.manufacturer === null) {
     this.manufacturer = "";
    }
   },
  },
  methods: {
   async getVehicleDetails() {
    if (this.vehicleDetails.token) {
     this.loading = true;
     const response = await axios.post("vehicle/details", { vehicleId: this.vehicleDetails.token });
     const vehicleDetails = response.data;
     this.vehicleData = {
      vehicleNo: vehicleDetails.vehicleno,
      manufacturer: vehicleDetails.details.manufacturer,
      model: vehicleDetails.details.model,
      color: vehicleDetails.details.color,
      year: vehicleDetails.details.year,
      fuel: vehicleDetails.details.fuel.length > 0 ? vehicleDetails.details.fuel[0] : "",
      rcbookExpiry: vehicleDetails.document.rcbook.expdate ? moment(vehicleDetails.document.rcbook.expdate, "YYYYMMDD").format("YYYY-MM-DD") : "",
      taxExpiry: vehicleDetails.document.tax.expdate ? moment(vehicleDetails.document.tax.expdate, "YYYYMMDD").format("YYYY-MM-DD") : "",
      fitnessExpiry: vehicleDetails.document.fitness.expdate ? moment(vehicleDetails.document.fitness.expdate, "YYYYMMDD").format("YYYY-MM-DD") : "",
      polutionExpiry: vehicleDetails.document.pollution.expdate ? moment(vehicleDetails.document.pollution.expdate, "YYYYMMDD").format("YYYY-MM-DD") : "",
      insuranceExpiry: vehicleDetails.document.insurance.expdate ? moment(vehicleDetails.document.insurance.expdate, "YYYYMMDD").format("YYYY-MM-DD") : "",
      carriagepermit: vehicleDetails.document.carriagepermit.expdate ? moment(vehicleDetails.document.carriagepermit.expdate, "YYYYMMDD").format("YYYY-MM-DD") : "",
     };
     this.vehicleData.rcbookExpiry = this.vehicleData.rcbookExpiry == "Invalid date" ? "" : this.vehicleData.rcbookExpiry;
     this.vehicleData.taxExpiry = this.vehicleData.taxExpiry == "Invalid date" ? "" : this.vehicleData.taxExpiry;
     this.vehicleData.fitnessExpiry = this.vehicleData.fitnessExpiry == "Invalid date" ? "" : this.vehicleData.fitnessExpiry;
     this.vehicleData.polutionExpiry = this.vehicleData.polutionExpiry == "Invalid date" ? "" : this.vehicleData.polutionExpiry;
     this.vehicleData.insuranceExpiry = this.vehicleData.insuranceExpiry == "Invalid date" ? "" : this.vehicleData.insuranceExpiry;
     this.vehicleData.carriagepermit = this.vehicleData.carriagepermit == "Invalid date" ? "" : this.vehicleData.carriagepermit;
     console.log("filter", this.filterData);
     this.vehicleData.manufacturer["model"] = this.filterData.manufacturer.find((data) => data.id === this.vehicleData.manufacturer.id).model;
     this.loading = false;
     this.tabs = "cab-profile-tab";
    }
   },
 
   nameKeydown(e) {
    if (/[^A-Z0-9]/gi.test(e.key)) {
     e.preventDefault();
    }
   },
   pasteKey(event) {
    let main_text = event.clipboardData.getData("text");
    main_text = main_text.replace(/[^A-Z0-9]/gi, "");
    setTimeout(() => {
     this.vehicleData.vehicleNo = main_text;
    }, 0);
   },
   async getFilters() {
    const response = await axios.get("vehicle/filters");
    this.filterData = response.data;
   },
   resetVehicleDetails() {
    (this.vehicleData = {
     vehicleNo: "",
     manufacturer: {},
     model: {},
     color: {},
     fuel: {},
     year: "",
     rcbookExpiry: "",
     taxExpiry: "",
     fitnessExpiry: "",
     polutionExpiry: "",
     insuranceExpiry: "",
     carriagepermit: "",
    }),
     (this.expiryDate = moment().add(2, "years").format("YYYY-MM-DD"));
    this.$v.$reset();
   },
   async submitDetails() {
    this.$v.$touch();

if (this.$v.$anyError) {
  this.$toasted.show("Please fix the errors before submitting.", {
    position: "bottom-center",
    duration: 4000,
  })
} else {
     const payload = {...this.vehicleData};
     if (!payload.fuel) payload.fuel = [{ id: 1, name: "Diesel" }];
     else payload.fuel = [payload.fuel];
     payload.rcbookExpiry = payload.rcbookExpiry ? moment(payload.rcbookExpiry).format("YYYYMMDD") : "";
     payload.taxExpiry = payload.taxExpiry ? moment(payload.taxExpiry).format("YYYYMMDD") : "";
     payload.fitnessExpiry = payload.fitnessExpiry ? moment(payload.fitnessExpiry).format("YYYYMMDD") : "";
     payload.polutionExpiry = payload.polutionExpiry ? moment(payload.polutionExpiry).format("YYYYMMDD") : "";
     payload.insuranceExpiry = payload.insuranceExpiry ? moment(payload.insuranceExpiry).format("YYYYMMDD") : "";
     payload.carriagepermit = payload.carriagepermit ? moment(payload.carriagepermit).format("YYYYMMDD") : "";
     if (!this.editStatus) {
      this.loading = true;
      let vehicleArray = [];
      vehicleArray.push(payload);
      const response = await axios.post("vehicle/insert", { vehicle: vehicleArray });
      if (response.status === 200) {
       this.loading = false;
       this.$toasted.show(response.data.message, {
        position: "bottom-center",
        duration: 4000,
       });
       this.$emit("vehicle-updated");
       this.resetVehicleDetails();
      } 
     else if (response.status === 206) {
       this.loading = false;
       this.$toasted.show(response.data, {
        position: "bottom-center",
        duration: 4000,
       });
      } 
      else {
       this.loading = false;
       this.$toasted.show(response.data, {
        position: "bottom-center",
        duration: 4000,
       });
      }
     } else {
      payload.token = this.vehicleDetails.token;
      this.loading = true;
      const response = await axios.patch("vehicle/update", payload);
      if (response.status === 200) {
       this.loading = false;
       this.$toasted.show(response.data.message, {
        position: "bottom-center",
        duration: 4000,
       });
       this.$emit("vehicle-updated");
      } else if (response.status === 206) {
       this.loading = false;
       this.$toasted.show(response.data, {
        position: "bottom-center",
        duration: 4000,
       });
      }
     }
    }
   },
   cancelSubmit() {
    this.vehicleNo = "";
    this.manufacturer = "";
    this.model = "";
    this.color = "";
    this.fuel = "";
    this.year = "";
    this.$v.$reset();
    this.$emit("cancel-insert");
   },
  },
  computed: {
   vehicleNoError() {
    const errors = [];
    if (!this.$v.vehicleData.vehicleNo.$dirty) return errors;
    !this.$v.vehicleData.vehicleNo.required && errors.push("Vehicle No is required.");
    return errors;
   },
   manufacturerError() {
    const errors = [];
    if (!this.$v.vehicleData.manufacturer.$dirty) return errors;
    !this.$v.vehicleData.manufacturer.required && errors.push("Manufacturer is required.");
    return errors;
   },
   modelError() {
    const errors = [];
    if (!this.$v.vehicleData.model.$dirty) return errors;
    !this.$v.vehicleData.model.required && errors.push("Model is required.");
    return errors;
   },
   colorError() {
    const errors = [];
    if (!this.$v.vehicleData.color.$dirty) return errors;
    !this.$v.vehicleData.color.required && errors.push("Color is required.");
    return errors;
   },
   yearError() {
    const errors = [];
    if (!this.$v.vehicleData.year.$dirty) return errors;
    !this.$v.vehicleData.year.required && errors.push("Year is required.");
    return errors;
   },
  },
 };
 </script>
 
 <style scoped lang="scss">
 @import "./vehicleInsert";
 </style>