<template>
 <div class="main-trip-list-blk">
  <div class="trip-header-blk">
   <span class="id-blk">ID </span>
   <span class="date-blk">Date</span>
   <span class="time-blk">Time</span>
   <span class="trip-type">Trip type</span>
   <span class="user-blk">Employees</span>
   <span class="driver-blk">Driver</span>
   <span class="package-blk">Package</span>
   <span class="fare-blk">Fare</span>
   <span class="remarks-blk">Remarks</span>
   <span class="action-blk" v-if="listType === 'dispute'">Actions</span>
  </div>
  <div class="trip-list-blk" v-for="(data, index) in tripList" :key="index">
   <div class="id-blk content-blk">
    <span>{{ index + 1 }}</span>
    <a @click="openDrawer(data.bigId)">{{ data.id }}</a>
    <span class="status-blk started-status-blk" v-if="data.status.id === 7">Started</span>
    <v-tooltip top v-if="data.employees && data.employees.length > 1" color="black">
     <template v-slot:activator="{ on, attrs }">
      <i style="cursor: pointer" class="fas fa-user-friends" v-on="on" v-bind="attrs"></i>
     </template>
     <div class="tooltip-content">
      <ul class="employee-list">
       <li v-for="(item, employeeIndex) in data.employees" :key="employeeIndex">{{ item.name }}</li>
      </ul>
     </div>
    </v-tooltip>
   </div>

   <div class="date-blk content-blk">
    <span>{{ getDate(data.schedule_date) }}</span>
   </div>
   <div class="time-blk content-blk">
    <span>{{ getTime(data.schedule_date) }}</span>
   </div>
   <div class="trip-type content-blk">
    <span>{{ data.tripType }}</span>
   </div>
   <div class="user-blk content-blk">
    <ul style="margin: 0; padding-left: 16px">
     <li v-for="(employee, employeeIndex) in data.employees" :key="employeeIndex">{{ employee.name }}</li>
    </ul>
   </div>
   <div class="driver-blk content-blk">
    <span>{{ data.driver.name }}</span>
   </div>
   <div class="package-blk content-blk">
    <span>{{ data.package }}</span>
   </div>
   <div class="fare-blk content-blk">
    <span>₹ {{ data.driverfare.total }}</span>
   </div>
   <div class="remarks-blk content-blk">
    <span> <v-btn color="#eea004" small @click="showRemarks(data)">Remarks</v-btn> </span>
   </div>
   <div class="action-blk content-blk" v-if="listType === 'dispute'">
    <span> <v-btn color="#eea004" small @click="openrdDialog(data.bigId)">Resolve</v-btn></span>
   </div>
  </div>

  <v-navigation-drawer v-model="drawer" absolute temporary width="1200">
   <TripDetails :tripId="tripId" v-if="drawer" @closeTripDetails="closeTripDetails"></TripDetails>
  </v-navigation-drawer>
  <v-dialog width="500" v-model="rdDialog">
   <v-card class="card-modal-view">
    <v-card-title>Resolve Dispute</v-card-title>
    <v-card-text>
     <div>
      <v-textarea v-model="remarks" rows="1"></v-textarea>
     </div>
    </v-card-text>
    <v-card-actions style="display: flex; justify-content: flex-end; gap: 10px">
     <v-btn small color="#ed5e5e" @click="rdDialog = false">Close</v-btn>
     <v-btn small color="#eea004" @click="submitReview">Confirm</v-btn>
    </v-card-actions>
   </v-card>
  </v-dialog>
  <v-dialog v-model="remarksDialog" width="60%" :scrollable="false">
   <v-card class="card-modal-view">
    <v-card-title>Remarks</v-card-title>
    <v-card-text class="remarks-list">
     <v-data-table :headers="headers" :items="tripRemarks" :items-per-page="-1" dense hide-default-footer fixed-header height="calc(100vh - 300px)">
      <template v-slot:item.date="{ item }">
       <td>{{ getDateTime(item.date) }}</td>
      </template>
      <template v-slot:item.type="{ item }">
       <td>
        <v-chip small :color="getColor(item.type)">
         {{ item.type === "dispute" ? "Dispute" : item.type === "response" ? "Resolve" : "Verified" }}
        </v-chip>
       </td>
      </template>
     </v-data-table>
    </v-card-text>
    <v-card-actions style="display: flex; justify-content: flex-end">
     <v-btn small color="#ed5e5e" @click="remarksDialog = false">Close</v-btn>
    </v-card-actions>
   </v-card>
  </v-dialog>
  <Loading v-if="loading"></Loading>
  <Confirm ref="confirm"></Confirm>
 </div>
</template>

<script>
import * as moment from "moment";
import TripDetails from "@/components/Trips/trip-details/TripDetails";
import TollAdd from "@/components/Trips/TollAdd";
import Loading from "@/components/common/Loading";
import Confirm from "@/components/common/Confirm";
import StartEndDialog from "@/components/Trips/trips-view/StartEndDialog";
import axios from "../../../axios/axios-global";
export default {
 name: "TripsView",
 components: { StartEndDialog, Confirm, Loading, TripDetails, TollAdd },
 props: {
  tripList: Array,
  listType: String,
  tab: Number,
 },
 data: () => {
  return {
   drawer: false,
   tripId: 0,
   loading: false,
   tollDialog: false,
   bigId: 0,
   status: "",
   rdDialog: false,
   remarks: "",
   remarksDialog: false,
   headers: [
    { text: "Date", value: "date" },
    { text: "Status", value: "type" },
    { text: "Remarks", value: "remark" },
   ],
   tripRemarks: [],
  };
 },
 methods: {
  getDate(data) {
   return data ? moment(data).format("DD-MM-YYYY") : "-";
  },
  getTime(data) {
   return data ? moment(data).format("HH:mm") : "-";
  },
  getDateTime(data) {
   return data ? moment(data).format("DD-MM-YYYY HH:mm") : "-";
  },
  openDrawer(id) {
   this.drawer = true;
   this.tripId = id;
  },
  closeTripDetails() {
   this.drawer = false;
  },
  closeDialog() {
   this.startDialog = false;
  },
  openrdDialog(id) {
   this.rdDialog = true;
   this.bigId = id;
  },
  showRemarks(data) {
   this.remarksDialog = true;
   this.tripRemarks = data.corporateverificationLog ? data.corporateverificationLog : [];
  },
  getColor(type) {
   if (!type) return "";
   let style = type === "dispute" ? "#ed5e5e" : type === "response" ? "#facc15" : "#4caf50";
   return style;
  },
  async submitReview() {
   this.loading = true;
   let requestBody = {
    response: this.remarks,
    tripid: this.bigId,
   };
   let response = await axios.post("/trip/dispute/resolve", requestBody);
   if (response && response.status === 200) {
    this.loading = false;
    this.rdDialog = false;
    this.$toasted.show(response.data.message, {
     position: "bottom-center",
     duration: 5000,
    });
    this.$emit("disputeResolved");
   } else {
    this.$toasted.show(response.data.message, {
     position: "bottom-center",
     duration: 5000,
    });
   }
  },
 },
 watch: {
  tab() {
   console.log("tab", this.tab);
   this.$emit("fetchTrips");
  },
 },
};
</script>

<style scoped lang="scss">
@import "../trips-view/tripView.scss";

::v-deep .v-data-table {
 .v-data-table__wrapper {
  table {
   tr {
    // th {
    //  width: 150px;
    // }
    td {
     max-width: 150px;
     border-bottom: 1px solid #0000001f;
    }
   }
  }
 }
}
</style>
