<template>
 <div class="driver-insert-modal">
  <v-card elevation="0" class="basic-details-modal">
   <span class="header-modal">{{ editStatus ? `Edit ${driverDetails.name}` : "Add Driver" }}</span>
   <div class="text-field-modal" ref="form">
    <div class="text-field-blk">
     <v-text-field
      dense
      outlined
      label="Name *"
      placeholder="Name"
      v-model="driverDetails.name"
      :error-messages="nameError"
      :rules="[rules.required, rules.noSpecialChars]"
      @blur="$v.driverDetails.name.$touch()"
      @input="$v.driverDetails.name.$touch()"
     >
     </v-text-field>
    </div>
    <div class="text-field-blk">
     <v-text-field
      dense
      outlined
      label="Mobile Number *"
      placeholder="Mobile Number"
      v-model="driverDetails.mobile"
      maxlength="10"
      :error-messages="mobileError"
      @blur="$v.driverDetails.mobile.$touch()"
      type="number"
      class="input-field"
      @input="checkMobileError"
      :key="driverDetails.mobileInputKey"
     >
     </v-text-field>
    </div>
    <div class="text-field-blk">
     <v-text-field
      dense
      outlined
      label="Additional Contact"
      placeholder="Additional Contact"
      v-model="driverDetails.additionalContact"
      maxlength="10"
      type="number"
      class="input-field"
      @input="checkLength"
      :key="driverDetails.inputKey"
     >
     </v-text-field>
     <!-- :error-messages="additionalContactError"
            @blur="$v.driverDetails.additionalContact.$touch()"
            @input="$v.driverDetails.additionalContact.$touch()" -->
    </div>
    <div class="text-field-blk">
     <v-text-field
      dense
      outlined
      label="Licence No *"
      placeholder="Licence No"
      v-model="driverDetails.licensefront.no"
      :error-messages="licenceNoError"
      :rules="[rules.required, rules.alphanumeric]"
      @blur="$v.driverDetails.licensefront.no.$touch()"
      @input="$v.driverDetails.licensefront.no.$touch()"
     >
     </v-text-field>
    </div>
    <div class="address-field-blk">
     <v-textarea
      label=" Address *"
      placeholder="Driver Address"
      :error-messages="addressError"
      outlined
      rows="1"
      :rules="[rules.required]"
      @input="$v.driverDetails.address.$touch()"
      @blur="$v.driverDetails.address.$touch()"
      v-model="driverDetails.address"
     ></v-textarea>
    </div>
    <div class="text-field-blk">
     <v-autocomplete
      :items="states"
      label="State *"
      item-text="name"
      item-value="id"
      outlined
      :error-messages="stateError"
      @input="$v.driverDetails.state.$touch()"
      @blur="$v.driverDetails.state.$touch()"
      v-model="driverDetails.state"
      @change="getFilteredCities"
      dense
     ></v-autocomplete>
    </div>
    <div class="text-field-blk">
     <v-autocomplete
      :items="fileredCities"
      label="City *"
      item-text="name"
      item-value="id"
      outlined
      :error-messages="cityError"
      @input="$v.driverDetails.city.$touch()"
      @blur="$v.driverDetails.city.$touch()"
      v-model="driverDetails.city"
      dense
     ></v-autocomplete>
    </div>
    <div class="text-field-blk">
     <v-menu v-model="dateModel" :nudge-right="40" :nudge-top="20" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
      <template v-slot:activator="{ on }">
       <v-text-field v-model="driverDetails.licensefront.expdate" outlined label="Licence Expiry Date" readonly dense v-on="on" hide-details> </v-text-field>
      </template>
      <v-date-picker v-model="driverDetails.licensefront.expdate" color="black" @input="dateModel = false" :min="maxDate"></v-date-picker>
     </v-menu>
    </div>
    <div class="text-field-blk">
     <v-menu v-model="PVDateModel" :nudge-right="40" :nudge-top="20" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
      <template v-slot:activator="{ on }">
       <v-text-field v-model="driverDetails.pocexpdate" outlined label="Police Verification Expiry Date" readonly dense v-on="on"> </v-text-field>
      </template>
      <v-date-picker @input="PVDateModel = false" :min="maxDate" v-model="driverDetails.pocexpdate" color="black"></v-date-picker>
     </v-menu>
    </div>
    <div class="text-field-blk">
     <v-text-field
      :rules="[rules.required, rules.alphanumeric]"
      dense
      outlined
      label="ID Proof No"
      placeholder="ID Proof No"
      v-model="driverDetails.idproofno"
      :error-messages="idError"
      @input="$v.driverDetails.idproofno.$touch()"
      @blur="$v.driverDetails.idproofno.$touch()"
     >
     </v-text-field>
    </div>
   </div>
   <div class="button-blk">
    <v-btn color="#facc15" @click="submitDetails">Submit</v-btn>
    <v-btn color="#ed5e5e" @click="cancelSubmit">Close</v-btn>
   </div>
  </v-card>
  <Loading v-if="loading"></Loading>
  <Confirm ref="confirm"></Confirm>
 </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { minLength, numeric, required, maxLength } from "vuelidate/lib/validators";
import * as moment from "moment";
import DocumentInsert from "@/components/driver-management/document-insert/DocumentInsert";
import axios from "@/axios/axios-global";
import Loading from "@/components/common/Loading";
import Confirm from "@/components/common/Confirm";
export default {
 name: "DriverInsert",
 components: { Confirm, Loading, DocumentInsert },
 props: {
  tabName: String,
  token: String,
  editStatus: Boolean,
 },
 data: () => {
  return {
   name: "",
   rules: {
    required: (value) => !!value || "This field is required",
    noSpecialChars: (value) => /^[a-zA-Z ]*$/.test(value) || "Numbers / special characters are not allowed",
    alphanumeric: (value) => /^[a-zA-Z0-9 ]*$/.test(value) || "Special characters are not allowed",
    // spacesChars
    // noSpecialChars: (value) =>
    //   /^[a-zA-Z ]*$/.test(value) || 'Numbers / special characters are not allowed',
   },
   mobile: "",
   additionalContact: "",
   licenceNo: "",
   buttonName: "submit",
   loading: false,
   driverId: "",
   documents: "",
   trash: true,
   isStatusChange: false,
   state: null,
   states: [],
   city: null,
   cities: [],
   fileredCities: [],
   address: null,
   expiryDate: null,
   maxDate: moment().format("YYYY-MM-DD"),
   dateModel: false,
   PVDateModel: false,
   PVExpiryDate: null,
   idProofNo: "",

   driverDetails: {
    id: "",
    name: "",
    mobile: "",
    additionalContact: "",
    licensefront: {
     no: "",
     expdate: moment().add(2, "years").format("DD-MM-YYYY"),
    },
    address: "",
    state: "",
    city: "",
    trash: false,
    pocexpdate: "",
    idproofno: "",

    inputKey: 0,
    mobileInputKey: 0,
   },
   errorMessages: [],
  };
 },
 mixins: [validationMixin],
 validations() {
  return {
   driverDetails: {
    name: { required },
    mobile: { required, minLength: minLength(10), maxLength: maxLength(10), numeric },
    licensefront: { no: { required } },
    additionalContact: { minLength: minLength(10), numeric },
    address: { required, minLength: minLength(3) },
    state: { required },
    city: { required },
    idproofno: { required },
   },
  };
 },
 methods: {
  checkMobileError() {
   this.$v.driverDetails.mobile.$touch();
  },
  checkLength() {
   if (this.driverDetails.additionalContact.length > 10) {
    this.driverDetails.additionalContact = this.driverDetails.additionalContact.slice(0, 10);
   }
  },
  requiredError(fieldName, label, form, length, type) {
   const errors = [];
   const field = form[fieldName];
   if (field && !field.$dirty) return errors;
   field && field.$invalid && errors.push(label + " is required");
   if (type && length && type !== "min-length") field && !field.minLength && errors.unshift(label + " must be " + length + " characters.");
   if (type && length && type === "min-length") field && !field.minLength && errors.unshift(label + " must be atleast " + length + " characters.");
   return errors;
  },
  getFilteredCities() {
   if (this.driverDetails.state) {
    this.fileredCities = this.cities.filter((city) => {
     if (city.state && city.state.id === this.driverDetails.state) {
      return city;
     }
    });
   } else {
    this.fileredCities = this.cities;
   }
  },
  async getStates() {
   this.states = [];
   const response = await axios.get("/driver/list-state");
   if (response.status === 200) {
    this.states = response.data;
   }
  },
  async getCities() {
   this.cities = [];
   const response = await axios.get("/driver/list-city");
   if (response.status === 200) {
    this.cities = response.data;
    if (this.editStatus) {
     this.getFilteredCities();
    }
   }
  },
  async submitDetails() {
   this.$v.$touch();
   this.errorMessages = [];
   const validations = [
    { field: "name", rules: ["required", "noSpecialChars"] },
    { field: "idproofno", rules: ["required", "alphanumeric"] },
    { field: "licensefront.no", rules: ["required", "alphanumeric"] },
   ];
   validations.forEach(({ field, rules }) => {
    const value = field.includes(".") ? this.driverDetails[field.split(".")[0]][field.split(".")[1]] : this.driverDetails[field];
    const error = rules.map((rule) => this.rules[rule](value)).find((v) => v !== true);

    if (error) {
     this.errorMessages.push(error);
    }
   });

   if (this.$v.$anyError || this.errorMessages.length > 0) {
    this.$toasted.show("Please fix the errors before submitting.", {
     position: "bottom-center",
     duration: 4000,
    });
   } else {
    if (this.editStatus === false) {
     let driverArray = [];
     const payload = this.driverDetails;
     payload.licensefront.expdate = payload.licensefront.expdate ? moment(payload.licensefront.expdate).format("YYYYMMDD") : "";
     payload.pocexpdate = payload.pocexpdate ? moment(payload.pocexpdate).format("YYYYMMDD") : "";
     driverArray.push(payload);
     let confirmTitle = "Confirm Add";
     let confirmText = "Please confirm to Add the driver";
     if (await this.$refs.confirm.open(confirmTitle, confirmText)) {
      this.loading = true;
      try {
       const response = await axios.post("driver/insert", { driver: driverArray });

       if (response.status === 200) {
        this.loading = false;
        this.$toasted.show(response.data.message, {
         position: "bottom-center",
         duration: 4000,
        });
        this.$emit("driver-updated");
       } else if (response.status === 206) {
        this.loading = false;
        this.$toasted.show(response.data, {
         position: "bottom-center",
         duration: 4000,
        });
       }
      } catch (error) {
       this.loading = false;
       this.$toasted.show(error.response, {
        position: "top-center",
        duration: 4000,
       });
      }
      ``;
     }
    } else if (this.editStatus === true) {
     let confirmTitle = "Confirm Edit";
     let confirmText = "Please confirm to Edit the driver";
     if (await this.$refs.confirm.open(confirmTitle, confirmText)) {
      this.loading = true;
      try {
       const payload = this.driverDetails;
       payload.licensefront.expdate = payload.licensefront.expdate ? moment(payload.licensefront.expdate).format("YYYYMMDD") : "";
       payload.pocexpdate = payload.pocexpdate ? moment(payload.pocexpdate).format("YYYYMMDD") : "";
       payload.trash = 1; // if editing the document then the driver is deactivated
       const response = await axios.patch("driver/update", payload);
       if (response.status === 200) {
        this.loading = false;
        this.$toasted.show(response.data, {
         position: "bottom-center",
         duration: 4000,
        });
        this.$emit("driver-updated");
       } else if (response.status === 206) {
        this.loading = false;
        this.$toasted.show(response.data, {
         position: "bottom-center",
         duration: 4000,
        });
       }
      } catch (error) {
       this.loading = false;
       this.$toasted.show(error.response, {
        position: "bottom-center",
        duration: 4000,
       });
      }
     }
    }
   }
  },
  async getDriverDetails() {
   const payload = {
    driverId: this.token,
   };
   const response = await axios.post("driver/details", payload);
   this.driverDetails = {
    id: this.token,
    name: response.data.name,
    mobile: response.data.mobile,
    additionalContact: response.data.details.contactno ? response.data.details.contactno : "",
    address: response.data.details.address ? response.data.details.address : "",
    state: response.data.state,
    city: response.data.city,
    licensefront: {
     no: response.data.document.licensefront.no,
     expdate: response.data.document.licensefront.expdate ? moment(response.data.document.licensefront.expdate, "YYYYMMDD").format("YYYY-MM-DD") : "",
    },
    trash: response.data.trash,
    pocexpdate: response.data.document.policeclearance.expdate ? moment(response.data.document.policeclearance.expdate, "YYYYMMDD").format("YYYY-MM-DD") : "",
    idproofno: response.data.document.idproof.no ? response.data.document.idproof.no : "",
   };
   this.driverDetails.licensefront.expdate = this.driverDetails.licensefront.expdate == "Invalid date" ? "" : this.driverDetails.licensefront.expdate;
   this.driverDetails.pocexpdate = this.driverDetails.pocexpdate == "Invalid date" ? "" : this.driverDetails.pocexpdate;
  },
  resetDriverDetails() {
   (this.driverDetails = {
    id: "",
    name: "",
    mobile: "",
    additionalContact: "",
    licensefront: {
     no: "",
     expdate: moment().add(2, "years").format("YYYY-MM-DD"),
    },
    address: "",
    state: "",
    city: "",
    trash: false,
    pocexpdate: "",
    idproofno: "",
   }),
    this.$v.$reset();
   this.buttonName = "submit";
   this.driverId = "";
  },
  cancelSubmit() {
   this.resetDriverDetails();
   this.$emit("cancel-insert");
  },
 },
 watch: {
  tabName() {
   if (this.editStatus === true && this.tabName === "insert-tab") {
    this.getDriverDetails();
    this.getFilteredCities();
    this.getStates();
    this.getCities();
   } else {
    this.resetDriverDetails();
   }
  },
 },
 mounted() {
  this.getStates();
  this.getCities();
  if (this.editStatus === true) {
   this.getDriverDetails();
  } else {
   this.resetDriverDetails();
  }
 },
 computed: {
  nameError() {
   const errors = [];
   if (!this.$v.driverDetails.name.$dirty) return errors;
   !this.$v.driverDetails.name.required && errors.push("Name is required.");
   return errors;
  },
  mobileError() {
   const errors = [];
   if (!this.$v.driverDetails.mobile.$dirty) return errors;
   !this.$v.driverDetails.mobile.required && errors.push("Number is required.");
   if (this.driverDetails.mobile.length < 10) {
    errors.push("Mobile number must be 10 digits");
   } else if (this.driverDetails.mobile.length > 10) {
    errors.push("Please Enter a valid Mobile");
   }
   !this.$v.driverDetails.mobile.numeric && errors.push("Enter a valid Number");

   return errors;
  },

  // additionalContactError() {
  //   const errors = [];
  //   if (!this.$v.driverDetails.additionalContact.$dirty) return errors;
  //   !this.$v.driverDetails.additionalContact.required && errors.push("Number is required.");
  //   !this.$v.driverDetails.additionalContact.minLength && errors.push("Enter a valid Number");
  //   !this.$v.driverDetails.additionalContact.numeric && errors.push("Enter a valid Number");
  //   return errors;
  // },
  addressError() {
   const errors = [];
   if (!this.$v.driverDetails.address.$dirty) return errors;
   !this.$v.driverDetails.address.required && errors.push("Address is required.");
   !this.$v.driverDetails.address.minLength && errors.push("Address must be atleast 3 characters long");
   return errors;
  },
  stateError() {
   const errors = [];
   if (!this.$v.driverDetails.state.$dirty) return errors;
   !this.$v.driverDetails.state.required && errors.push("State is required.");
   return errors;
  },
  cityError() {
   const errors = [];
   if (!this.$v.driverDetails.city.$dirty) return errors;
   !this.$v.driverDetails.city.required && errors.push("City is required.");
   return errors;
  },
  licenceNoError() {
   const errors = [];
   if (!this.$v.driverDetails.licensefront.no.$dirty) return errors;
   !this.$v.driverDetails.licensefront.no.required && errors.push("Licence No is required.");
   return errors;
  },
  idError() {
   const errors = [];
   if (!this.$v.driverDetails.idproofno.$dirty) return errors;
   !this.$v.driverDetails.idproofno.required && errors.push("Enter a valid Number");
   return errors;
  },
 },
};
</script>

<style scoped lang="scss">
@import "src/components/driver-management/driver-insert/driverInsert";

/* Hide arrows in number input */
::v-deep .v-text-field input[type="number"]::-webkit-inner-spin-button,
.v-text-field input[type="number"]::-webkit-outer-spin-button {
 -webkit-appearance: none;
 margin: 0;
}

::v-deep .v-text-field input[type="number"] {
 -moz-appearance: textfield; /* For Firefox */
}
</style>
