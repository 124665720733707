<template>
 <div class="vehicle-list">
  <div class="button-blk">
   <v-btn @click="closeDialog" color="#ed5e5e" text small><i class="fas fa-times"></i></v-btn>
  </div>
  <v-data-table :headers="headers" :items="getRemarks" :items-per-page="-1" hide-default-footer>
   <template v-slot:item.status="{ item }">
    <v-chip :color="getRemarksStatusColor(item.action)" small dark>{{ getRemarksStatus(item.action) }}</v-chip>
   </template>
  </v-data-table>
 </div>
</template>

<script>
import moment from "moment";
import axios from "@/axios/axios-global";
export default {
 props: {
  token: String,
 },
 data: () => {
  return {
   headers: [
    { text: "Employee", sortable: true, value: "remarksBy" },
    { text: "Reason", value: "reason" },
    { text: "Date", value: "date" },
    { text: "Status", value: "status", sortable: true },
   ],
   Remarks: [],

   vehicleDetails: {},
  };
 },
 mounted() {
  this.getVehicleDetails();
 },
 methods: {
  async getVehicleDetails() {
   const payload = {
    vehicleId: this.token,
   };
   const response = await axios.post("vehicle/details", payload);
   this.vehicleDetails = response.data;
  },
  getRemarksStatus(status) {
   if (status === 0) {
    return "Activated";
   } else if (status === 1) {
    return "Deactivated";
   } else if (status === 2) {
    return "Rejected";
   } else {
    return "Verified";
   }
  },
  getRemarksStatusColor(status) {
   if (status === 0) {
    return "#0DA300";
   } else if (status === 1) {
    return "red";
   } else if (status === 2) {
    return "#EB6746";
   } else {
    return "green";
   }
  },
  closeDialog() {
   this.$emit("edit-dialog");
  },
 },
 computed: {
  getRemarks() {
   if (this.vehicleDetails && this.vehicleDetails.reason && this.vehicleDetails.reason.length > 0) {
    this.vehicleDetails.reason.map((remark) => {
     remark.date = remark.date ? moment(remark.date).format("YYYY-MM-DD HH:mm:ss") : "Not found";
     remark.remarksBy = remark.remarksBy.name ? remark.remarksBy.name : "-";
     return remark;
    });
    return this.vehicleDetails.reason;
   } else {
    return [];
   }
  },
 },
};
</script>

<style scoped lang="scss">
.vehicle-list {
 padding: 10px;
 .button-blk {
  display: flex;
  flex-direction: row-reverse;
  .fas {
   color: red;
   font-size: 1.3rem;
  }
 }
}
</style>
