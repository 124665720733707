<template>
 <section>
  <div class="driver-dashboard">
   <div class="driver-dashboard-container">
    <v-card class="filter-block">
     <Filters :placeHolder="'Search Driver Name/Number'" @get-filter="getFilters"></Filters>
    </v-card>

    <v-list>
     <v-subheader>
      <div class="count-block">
       <v-chip small
        >Total Drivers - <b>{{ pageCount }}</b></v-chip
       >
       <v-chip small
        >Active - <b>{{ active }}</b></v-chip
       >
       <v-chip small
        >Inactive - <b>{{ inactive }}</b></v-chip
       >
       <v-chip small
        >Deleted - <b>{{ deleted }}</b></v-chip
       >
       <!-- <v-chip small
        >Active Drivers <b>{{ pageCount }}</b></v-chip
       > -->
      </div>
      <v-btn small color="#377165" @click="openDialog({}, 'insert')"> Add Driver <i class="fas fa-plus-circle"></i></v-btn>
     </v-subheader>
     <v-data-table
      v-model:sort-by="sortBy"
      v-model:sort-desc="sortDesc"
      :items="items"
      :headers="headers"
      item-value="id"
      height="calc(100vh - 315px)"
      hide-default-footer
      class="sticky-header"
      @update:sort-by="onSortChange"
     >
      <template v-slot:body="{ items, headers }">
       <tbody>
        <tr :class="{ deleted: driver.trashStatus === 1 }" v-for="(driver, index) in driverList" :key="index">
         <td>
          <div class="driver-details">
           <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
             <span class="active-status" :class="{ inactive: driver.trash || driver.trashStatus }" v-on="on" v-bind="attrs"></span>
            </template>
            <span>{{ driver.trash ? "inactive" : "active" }}</span>
           </v-tooltip>

           <div class="image-block" v-if="driver.image">
            <span><img :src="driver.image" alt="" /></span>
           </div>
           <div class="details">
            <span :class="{ deleted: driver.trashStatus }">{{ driver.name }}</span>
            <span>{{ driver.mobile }}</span>
           </div>
          </div>
         </td>

         <td>
          <div v-if="!driver.trash && driver.trashStatus === 0" class="vehicle-chip">
           <v-btn x-small color="#4c99af" @click="openDialog(driver, 'vehicleAdd')">View & Add Vehicle</v-btn>
          </div>
          <!-- <v-btn v-else small color="#4c99af" @click="openDialog(driver, 'vehicleAdd')">Add Vehicle</v-btn> -->
         </td>

         <td>
          <div class="licence-column" v-if="Object.keys(driver.documents.licence).length > 0 && driver.trashStatus === 0">
           <span>{{ driver.documents.licence.no }}</span>
           <span v-if="driver.documents.licence.expdate"
            >Valid Upto : {{ getExpiry(driver.documents.licence.expdate) }}
            <i class="fas fa-exclamation-triangle" v-if="checkExpiry(driver.documents.licence.verified)" :class="{ 'expiry-soon': driver.documents.licence.verified === 3 }"></i
           ></span>
          </div>
         </td>
         <td>
          <div v-if="driver.trashStatus === 0" class="licence-column">
           <span v-if="driver.documents.idproof.no">{{ driver.documents.idproof.no }}</span>
           <span v-if="driver.documents.policeclearance.expdate"
            >POC Valid Upto : {{ getExpiry(driver.documents.policeclearance.expdate) }}
            <i class="fas fa-exclamation-triangle" v-if="checkExpiry(driver.documents.policeclearance.verified)" :class="{ 'expiry-soon': driver.documents.policeclearance.verified === 3 }"></i
           ></span>
          </div>
         </td>
         <td>
          <v-btn v-if="driver.trashStatus === 0" x-small color="#4c99af" @click="openDialog(driver, 'document')">Documents</v-btn>
         </td>
         <td>
          <span v-if="driver.trashStatus === 0">{{ driver.version }}</span>
         </td>
         <td class="action-column">
          <v-tooltip v-if="driver.trashStatus === 0" bottom>
           <template v-slot:activator="{ on, attrs }">
            <i class="fas fa-pencil-alt" @click="openDialog(driver, 'edit')" v-on="on" v-bind="attrs"></i>
           </template>
           <span>Edit</span>
          </v-tooltip>
          <v-tooltip v-if="driver.trashStatus === 0" bottom>
           <template v-slot:activator="{ on, attrs }">
            <i class="fas fa-trash" v-if="!driver.trash" @click="openDialog(driver, 'statusChange')" v-on="on" v-bind="attrs"></i>
            <i class="fas fa-undo" v-else @click="openDialog(driver, 'statusChange')" v-on="on" v-bind="attrs"></i>
           </template>
           <span>{{ driver.trash ? "Activate" : "Deactivate" }}</span>
          </v-tooltip>
          <v-tooltip bottom v-if="driver.trashStatus === 0">
           <template v-slot:activator="{ on, attrs }">
            <i class="fas fa-info-circle" @click="openDialog(driver, 'remarks')" v-on="on" v-bind="attrs"></i>
           </template>
           <span>Remarks</span>
          </v-tooltip>

          <button v-if="driver.trashStatus === 0" @click="deleteDriver(driver)" class="delete-btn" style="padding: 4px; border-radius: 4px; color: white; background-color: red">Delete</button>
          <div v-else class="deleted-row">
            <div>Driver Deleted</div>
            <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
            <div style="margin-left: 1rem; color:green; cursor: pointer;"><i class="fas fa-undo"  @click="restoreDriver(driver)"  v-on="on" v-bind="attrs"></i></div>
            </template>
            <span>Restore</span>
            </v-tooltip>
          </div>
         </td>
         <td>
         </td>
        </tr>
       </tbody>
      </template>
     </v-data-table>
     <v-pagination v-model="page" :length="parseInt(pageCount / 20) + 1"></v-pagination>
    </v-list>
   </div>
   <v-dialog v-model="dialog" v-if="dialog" :width="dialogWidth" persistent>
    <v-card class="card-container">
     <DriverInsert v-if="dialogStatus === 'edit'" :token="driverDetails.token" :editStatus="true" @cancel-insert="cancelInsert" @driver-updated="updateDriver"></DriverInsert>

     <DriverInsert v-if="dialogStatus === 'insert'" :token="''" :editStatus="false" @cancel-insert="cancelInsert" @driver-updated="updateDriver"></DriverInsert>

     <DocumentInsert v-if="dialogStatus === 'document'" @recalling="test" :token="driverDetails.token" @cancel-insert="cancelInsert"></DocumentInsert>

     <DeactivationModal
      v-if="dialogStatus === 'statusChange'"
      :title="driverDetails.trash ? 'Activate' : 'Deactivate'"
      :token="driverDetails.token"
      @dialog-closed="cancelInsert"
      @change-status="updateDriver"
      :type="1"
     ></DeactivationModal>
     <VehicleAssign @cancel-insert="cancelInsert" v-if="dialogStatus === 'vehicleAdd'" :driverDetails="driverDetails" @update-driver="updateDriver"></VehicleAssign>

     <DriversRemarksList v-if="dialogStatus === 'remarks'" :token="driverDetails.token" :type="1" @edit-dialog="cancelInsert"></DriversRemarksList>
    </v-card>
   </v-dialog>

   <Confirm ref="confirm"></Confirm>
   <v-dialog v-model="deleteDialog" max-width="600px">
    <v-card>
     <v-card-title> Confirm Deletion </v-card-title>

     <v-card-text style="color: black; font-size: 16px; margin-top: 2px">
      Are you sure you want to delete the driver <strong style="margin-left: 2px">{{ driverdata.name }}</strong
      >?
     </v-card-text>
     <v-card-text>
      <v-textarea label="Remarks *" :rules="[(v) => !!v || 'Remarks are required']" v-model="deleteRemarks" outlined rows="3" placeholder="Enter remarks "></v-textarea>
     </v-card-text>
     <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn outlined color="grey darken-1" text @click="deleteDialog = false"> Cancel </v-btn>

      <v-btn class="red darken-1" color="white" text @click="confirmDelete"> Delete </v-btn>
     </v-card-actions>
    </v-card>
   </v-dialog>

   <Loading v-if="loading"></Loading>
  </div>
 </section>
</template>

<script>
import Filters from "@/components/common/filter/Filters";
import axios from "@/axios/axios-global";
import moment from "moment";
import DriverInsert from "../driver-insert/DriverInsert.vue";
import DocumentInsert from "../document-insert/DocumentInsert.vue";
import DeactivationModal from "../../modal/DeactivationModal.vue";
import VehicleAssign from "../VehicleAssign.vue";
import Confirm from "../../common/Confirm.vue";
import Loading from "../../common/Loading.vue";
import DriversRemarksList from "../drivers-remarks-list/DriversRemarksList.vue";
export default {
 name: "DriverDashboardNew",
 components: { Confirm, Loading, Filters, DriverInsert, DocumentInsert, DeactivationModal, VehicleAssign, DriversRemarksList },
 data() {
  return {
   headers: [
    { text: "Name", value: "name" },

    { text: "Vehicles", value: "vehicle", sortable: false },
    { text: "Licence Details", value: "licence", sortable: false },
    { text: "ID Proof & PoC validity", value: "idproof", sortable: false },
    { text: "Documents", value: "documnets", sortable: false },
    { text: "Version", value: "versions", sortable: true },
    { text: "Actions", value: "actions", sortable: false },
   ],
   driverList: "",
   sortBy: "version",
   dialog: false,
   driverToken: "",
   dialogStatus: "",
   dialogWidth: "1000",
   loading: false,
   page: 1,
   pageCount: 10,
   items: [],
   versionSort: false,
   sortDesc: 1,
   deleteDialog: false,
   deleteRemarks: "",
   driverdata: "",
   active: 0,
   inactive: 0,
   deleted: 0,
  };
 },
 watch: {
  page() {
   this.getDriverList({ searchValue: "", initial: true, page: this.page });
  },
 },
 mounted() {
  this.getDriverList({ searchValue: "", initial: true, page: this.page });
 },
 methods: {
  test() {
   this.getDriverList({ searchValue: "", initial: true, page: this.page });
  },
  async confirmDelete() {
   try {
    if (!this.deleteRemarks.trim()) {
     this.$toasted.show("Please Enter Remarks  for deletion", {
      position: "top-center",
      duration: 4000,
      type: "error",
     });
     return;
    }

    const payload = {
     remark: this.deleteRemarks,
     token: this.driverdata.token,
     trash: 1,
    };
    const response = await axios.post("driver/moveToTrash", payload);
    if (response.status === 200) {
     this.deleteDialog = false;
     this.deleteRemarks = "";
     this.getDriverList({ searchValue: "", initial: false, page: this.page });
     this.$toasted.show("Driver Deleted Successfully", {
      position: "bottom-center",
      duration: 4000,
      type: "success",
     });
    } else {
     this.$toasted.show("An error occurred while deleting the driver", {
      position: "bottom-center",
      duration: 4000,
      type: "error",
     });
    }
   } catch (error) {
    console.error("Error deleting driver:", error);
    this.$toasted.show("An error occurred while deleting the driver", {
     position: "bottom-center",
     duration: 4000,
     type: "error",
    });
   }
  },

  deleteDriver(data) {
   this.deleteDialog = true;
   this.driverdata = data;
  },
   async restoreDriver(data) {
     const confirmTitle = "Confirm Restore";
     const confirmText = `Are you sure you want to restore the driver "${data.name}"?`;
     const confirmIcon = "fas fa-undo";
     const confirmed = await this.$refs.confirm.open(confirmTitle, confirmText, {
       color: "green",
       icon: confirmIcon,
     });
     if (confirmed) {
       try {
         const payload = {
           token: data.token,
           trash: 0,
         }
         const response = await axios.post("driver/restore", payload);
         this.$toasted.show(response.data, {
           position: "bottom-center",
           duration: 4000,
           type: response.status === 200 ? "success" : "error",
         });

       } catch (error) {
         this.$root.$emit("show_snackbar", { text: error, color: "#FF0000" });
         console.error(error);
       } finally {
        this.getDriverList({ searchValue: "", initial: false, page: this.page });
       }
     }
   },
  onSortChange() {
   if (this.sortBy === "version") {
    this.sortDesc = this.sortDesc === 1 ? -1 : 1;
    this.getFilters({ searchValue: "", page: this.page, sort: this.sortDesc });
   }
  },

  getFilters(data) {
   this.filters = data;
   this.filters.token = "";
   if (this.filters.searchValue === "") {
    this.page = 1;
    this.getDriverList(this.filters);
   } else {
    this.getDriverList({ searchValue: this.filters && this.filters.searchValue ? this.filters.searchValue.trim() : "", page: 1, sort: this.sortDesc });
   }
  },
  async getDriverList(data) {
   this.loading = true;
   const response = await axios.post("driver/list", {
    search: data.searchValue,
    page: data.page,
    items: this.items,
    sort: this.sortDesc,
   });
   this.loading = false;
   this.driverList = response.data.list;
   this.pageCount = response.data.total;
   this.active = response.data.active;
   this.inactive = response.data.deactive;
   this.deleted = response.data.deleted;
  },
  getExpiry(date) {
   date = date ? moment(date, "YYYYMMDD").format("DD-MM-YYYY") : "";
   date = date && date != "Invalid date" ? date : "-";
   return date;
  },

  checkExpiry(data) {
   return [3, 4].includes(data) ? true : false;
  },

  openDialog(data, status) {
   this.dialog = true;
   this.driverDetails = data;
   this.dialogStatus = status;
   if (status === "document") this.dialogWidth = "1200";
   else if (status === "statusChange" || status === "vehicleAdd" || status === "restore") this.dialogWidth = "500";
   else this.dialogWidth = "1000";
  },
  updateDriver() {
   this.dialog = false;
   console.log("dialog");
   this.getDriverList({ searchValue: "", initial: false, page: this.page });
  },
  cancelInsert() {
   this.dialog = false;
  },
 },
};
</script>

<style lang="scss" scoped>
.driver-dashboard {
 .driver-dashboard-container {
  .title-text {
   font-size: 1.25rem; /* Adjust font size as needed */
   font-weight: 700; /* Make text darker (bold) */
   color: #333; /* Darker text color */
  }
  .deleted {
   background-color: rgb(255, 230, 230);
  //  pointer-events: none;
  }

  .text-content {
   font-size: 1.1rem; /* Slightly larger font size */
   font-weight: 600; /* Semi-bold for better emphasis */
   color: #444; /* Darker text color */
  }

  .deleted {
   color: #ff4444;
  }

  padding: 10px;
  .filter-block {
   padding: 20px;
   background: #fff;
   box-shadow: 0 3px 6px rgba(25, 32, 36, 0.16), 0 -1px 4px rgba(25, 32, 36, 0.04);
   border-radius: 5px;
  }
  ::v-deep .v-list {
   border-radius: 5px !important;
   margin-top: 20px;
   padding: 20px !important;
  }

  .v-subheader {
   justify-content: space-between;

   .count-block {
    display: flex;
    align-items: center;
    margin-right: 10px;
    gap: 10px;
    b {
     //  font-size: 1.1rem;
     margin-left: 8px;
    }
    // b {
    //  //   font-size: 1.2rem;
    //  background: #c9c9c9;
    //  border-radius: 50%;
    //  width: 20px;
    //  height: 20px;
    //  text-align: center;
    //  font-size: 12px;
    // }
   }
  }

  ::v-deep .sticky-header thead {
   position: sticky;
   top: 0;
   background-color: white; /* Match background color to avoid transparency */
   z-index: 10; /* Ensures the header stays above the table content */
  }

  .driver-details {
   display: flex;
   align-items: center;
   padding: 5px 0;
   ::v-deep .active-status {
    width: 10px;
    height: 10px;
    background: green;
    bottom: 0;
    right: 10px;
    border-radius: 50%;
    margin-right: 10px;
   }
   .inactive {
    background: red;
   }
   .image-block {
    overflow: hidden;
    width: 45px;
    height: 45px;
    position: relative;
    box-sizing: content-box;
    border: #ffffff solid 4px;
    outline: 1px solid #d4d4d4;
    line-height: 1px;
    border-radius: 50%;
    background: #ffffff;
    margin-right: 15px;

    img {
     width: 100%;
     height: 100%;
     object-fit: cover;
    }
   }
   .details {
    display: grid;
   }
  }
  .licence-column {
   display: grid;
   .fas {
    color: rgb(218, 3, 3);
    font-size: 12px;
   }
   .expiry-soon {
    color: #ffbe01;
    stroke: #010101;
   }
  }
  .action-column {
   .fas {
    margin-right: 15px;
    cursor: pointer;
   }
   .deleted-row {
     display: flex;
     gap: 10px;
   }
  }
  .vehicle-chip {
   display: flex;
   align-items: center;
   gap: 5px;
   .fas {
    margin-left: 5px;
    color: rgb(120, 33, 33);
    cursor: pointer;
   }
   .extra-column {
    font-size: 11px;
   }
  }

  ::v-deep .v-btn {
   color: white;
   font-weight: 700;
   .fas {
    margin-left: 5px;
   }
  }
 }
 ::v-deep .v-card {
  padding: 30px;
 }
}
</style>
